import crypto from 'crypto'
import { MOMO_URL, MOMO_SECRET, MOMO_PARTNER_CODE } from '../config/environments'

const partnerCodeMomo = MOMO_PARTNER_CODE
const secretKeyMomo = MOMO_SECRET || ''
const domainMomo = MOMO_URL

export const getStoreSlugMomo = (momoStoreId: string = '') => {
  return `${partnerCodeMomo}-${momoStoreId}`
}

export const getSignatureMomo = (data: string) => {
  return crypto.createHmac('sha256', secretKeyMomo).update(data).digest('hex')
}

export const getDomainMomo = () => domainMomo

export const getPartnertCode = () => partnerCodeMomo

export const formatVND = (amount: number): string => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  }).format(amount)
}
