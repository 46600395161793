export const WOMEN_8T3_MENUS = [
  {
    menu: 'Bánh thiên nga',
    concept: 'Bánh thiên nga',
    desc: 'Bánh kem thiên nga trắng - Fruit Bloom Swan',
    sku: 'V9271',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh thiên nga',
    concept: 'Bánh thiên nga',
    desc: 'Bánh kem thiên nga đen - Golden Berries Swan',
    sku: 'V9273',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Bánh thiên nga',
    concept: 'Bánh thiên nga',
    desc: 'Bánh kem thiên nga hồng - Pink Ombre Swan',
    sku: 'V9453',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa tulip giấy hồng 8/3 (hộp mica)',
    sku: 'V9434',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa tulip giấy hồng 8/3 (hộp mica)',
    sku: 'V9435',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa tulip giấy hồng 8/3 (hộp mica)',
    sku: 'V9436',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa tulip đỏ vải voan (hộp mica)',
    sku: 'V9437',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa tulip đỏ vải voan (hộp mica)',
    sku: 'V9438',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa tulip đỏ vải voan (hộp mica)',
    sku: 'V9439',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa dâu tây đèn nháy (hộp mica)',
    sku: 'V9187',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa dâu tây đèn nháy (hộp mica)',
    sku: 'V9189',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa dâu tây đèn nháy (hộp mica)',
    sku: 'V9191',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa nho xoài đèn nháy (hộp mica)',
    sku: 'V9440',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa nho xoài đèn nháy (hộp mica)',
    sku: 'V9441',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Bánh kem bó hoa nho xoài đèn nháy (hộp mica)',
    sku: 'V9442',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Set bánh su kem Singapore bó hoa đỏ',
    sku: 'V9443',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Set bánh su kem Singapore bó hoa đỏ',
    sku: 'V9444',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Set bánh su kem Singapore bó hoa đỏ',
    sku: 'V9445',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Set bánh cupcake tulip',
    sku: 'V9446',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Set bánh cupcake tulip',
    sku: 'V9447',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Set bánh cupcake tulip',
    sku: 'V9448',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Bó hoa',
    desc: 'Set bánh cupcake tulip',
    sku: 'V9449',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Box hoa',
    desc: 'Box bánh kem việt quất hồng và hoa',
    sku: 'V9450',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Box hoa',
    desc: 'Box bánh kem tua rua hồng đen và hoa',
    sku: 'V9451',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Bánh hoa',
    concept: 'Box hoa',
    desc: 'Box bánh kem dâu tây và hoa',
    sku: 'V9452',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Su kem',
    desc: 'Hộp 12 bánh su kem Singapore trang trí hoa quả',
    sku: 'V8993',
    giftName: 'sparkle',
    sparkleNeed: 5,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Su kem',
    desc: 'Set bánh su kem Singapore hoa quả chữ nhật (48 bánh)',
    sku: 'V8838',
    giftName: 'sparkle',
    sparkleNeed: 1,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Cupcake',
    desc: 'Set cupcake viết chữ 20 bánh',
    sku: 'V9483',
    giftName: 'sparkle',
    sparkleNeed: 1,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Cupcake',
    desc: 'Set cupcake chà bông trứng muối',
    sku: 'V9280',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Cupcake',
    desc: 'Set cupcake chà bông trứng muối',
    sku: 'V9281',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Cupcake',
    desc: 'Set cupcake chà bông trứng muối',
    sku: 'V9282',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Cupcake',
    desc: 'Set cupcake chà bông trứng muối',
    sku: 'V9283',
    giftName: 'sparkle',
    sparkleNeed: 1,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Cupcake',
    desc: 'Set cupcake hoa quả',
    sku: 'V9284',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Cupcake',
    desc: 'Set cupcake hoa quả',
    sku: 'V9285',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Cupcake',
    desc: 'Set cupcake hoa quả',
    sku: 'V9286',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Cupcake',
    desc: 'Set cupcake hoa quả',
    sku: 'V9287',
    giftName: 'sparkle',
    sparkleNeed: 1,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh chữ nhật',
    desc: 'Bánh bông lan trứng muối cắt miếng viết chữ',
    sku: 'V9482',
    giftName: 'sparkle',
    sparkleNeed: 1,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh chữ nhật',
    desc: 'Bánh kem chữ nhật cắt miếng viết chữ',
    sku: 'V2603',
    giftName: 'sparkle',
    sparkleNeed: 1,
    B6: 0
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh cốc',
    desc: 'Set Sweet Sunshine (bltm - bánh kem bắp - mousse xoài)',
    sku: 'V9041',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh cốc',
    desc: 'Set Sweet Sunshine (bltm - bánh kem bắp - mousse xoài)',
    sku: 'V9043',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh cốc',
    desc: 'Set Sweet Sunshine (bltm - bánh kem bắp - mousse xoài)',
    sku: 'V9045',
    giftName: 'sparkle',
    sparkleNeed: 1,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh cốc',
    desc: 'Set mousse trang trí hoa quả (mousse dâu - xoài - sữa chua nho)',
    sku: 'V8321',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh cốc',
    desc: 'Set mousse trang trí hoa quả (mousse dâu - xoài - sữa chua nho)',
    sku: 'V8323',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh cốc',
    desc: 'Set mousse trang trí hoa quả (mousse dâu - xoài - sữa chua nho)',
    sku: 'V8327',
    giftName: 'sparkle',
    sparkleNeed: 1,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh cốc',
    desc: 'Set Classic Tiramisu (cacao - oreo - matcha)',
    sku: 'V9065',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh cốc',
    desc: 'Set Classic Tiramisu (cacao - oreo - matcha)',
    sku: 'V9067',
    giftName: 'sparkle',
    sparkleNeed: 2,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh cốc',
    desc: 'Set Classic Tiramisu (cacao - oreo - matcha)',
    sku: 'V9069',
    giftName: 'sparkle',
    sparkleNeed: 1,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh trái tim',
    desc: 'Set mousse tim trang trí hoa quả',
    sku: 'V9422',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh trái tim',
    desc: 'Tiramisu tim hoa quả',
    sku: 'V9563',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Bánh trái tim',
    desc: 'Set bông lan trứng muối tim',
    sku: 'V9565',
    giftName: 'card',
    sparkleNeed: 0,
    B6: ''
  },
  {
    menu: 'Sự kiện',
    concept: 'Truffle',
    desc: 'Hộp truffle 9 viên kèm thiệp và túi',
    sku: 'V2204',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sự kiện',
    concept: 'Truffle',
    desc: 'Hộp truffle 12 viên kèm thiệp và túi',
    sku: 'V2205',
    giftName: 'card',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh Red Velvet Sữa Chua Việt Quất',
    sku: 'V2709',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh Red Velvet Sữa Chua Việt Quất',
    sku: 'V2711',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh Red Velvet Sữa Chua Việt Quất',
    sku: 'V2713',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh Bông lan trứng muối xoài',
    sku: 'V2662',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh Bông lan trứng muối xoài',
    sku: 'V2663',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh Bông lan trứng muối xoài',
    sku: 'V2664',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem bơ xoài việt quất',
    sku: 'V2879',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem bơ xoài việt quất',
    sku: 'V2881',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem bơ xoài việt quất',
    sku: 'V2883',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem sữa chua hoa quả',
    sku: 'V1506',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem sữa chua hoa quả',
    sku: 'V1507',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem sữa chua hoa quả',
    sku: 'V1531',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem Triple Choco',
    sku: 'V1393',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem Triple Choco',
    sku: 'V1394',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem Triple Choco',
    sku: 'V1460',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem nho xoài',
    sku: 'V2349',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem nho xoài',
    sku: 'V2359',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem nho xoài',
    sku: 'V2369',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh Bông lan trứng muối sốt phô mai',
    sku: 'V2665',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh Bông lan trứng muối sốt phô mai',
    sku: 'V2666',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh Bông lan trứng muối sốt phô mai',
    sku: 'V2667',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem bơ nho việt quất',
    sku: 'V9346',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem bơ nho việt quất',
    sku: 'V9347',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem bơ nho việt quất',
    sku: 'V9348',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 0
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem nơ coquette hồng đen',
    sku: 'V8804',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem nơ coquette hồng đen',
    sku: 'V8806',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Bánh kem',
    desc: 'Bánh kem nơ coquette hồng đen',
    sku: 'V8808',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Mousse',
    desc: 'Mousse sữa chua việt quất',
    sku: 'V2414',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Mousse',
    desc: 'Mousse sữa chua việt quất',
    sku: 'V2415',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Mousse',
    desc: 'Mousse sữa chua việt quất',
    sku: 'V2416',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Mousse',
    desc: 'Mousse chanh leo',
    sku: 'V1401',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Mousse',
    desc: 'Mousse chanh leo',
    sku: 'V1402',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Mousse',
    desc: 'Mousse chanh leo',
    sku: 'V1741',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Mousse',
    desc: 'Mousse xoài',
    sku: 'V1849',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Mousse',
    desc: 'Mousse xoài',
    sku: 'V1864',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  },
  {
    menu: 'Sinh nhật',
    concept: 'Mousse',
    desc: 'Mousse xoài',
    sku: 'V1879',
    giftName: 'no-gift',
    sparkleNeed: 0,
    B6: 1
  }
]
