import React, { Fragment, SyntheticEvent, FormEvent } from 'react'
import {
  Modal,
  Header,
  Button,
  Grid,
  Form,
  Divider,
  Segment,
  Checkbox,
  Dimmer,
  Loader,
  Input,
  InputProps
} from 'semantic-ui-react'
import { concat, debounce, get, includes, replace } from 'lodash'
import { voucherStore } from '../../../model/voucher.model'
import Bluebird from 'bluebird'
import { FormattedCurrency } from '../../../components/FormattedCurrency'
import { InputCurrency } from '../../../components/InputCurrency'
import { observer, inject } from 'mobx-react'
import { ChangeEvent } from 'cleave.js/react/props'
import { AddPayment } from '../../../components/AddPayment'
import { OrderStore } from '../../../model/order.store'
import { InputNowOrder } from '../../../components/InputNowOrder'
import { toast } from 'react-toastify'
import { RootStore } from '../../../model/root-store'
import { getStoreSlugMomo, getSignatureMomo, getDomainMomo, formatVND } from '../../../libs/helper'
import { getObjectId } from '../../../libs/object-id'
import { handleAgentErrorResponse } from '../../../libs/error-handler'
import QRCode from 'qrcode.react'
import { getMilliseconds } from 'date-fns'

interface ModalPaymentProps {
  orderStore: OrderStore
  rootStore?: RootStore
  buttonActions: React.ReactNode[] /* Nhận vào một array các button để render ở đoạn cuối. Lưu ý nút Đóng để tắt modal
  luôn luôn hiện */
}

interface ModalPaymentState {
  receivedMoney: string
  couponCode: string
  saving: boolean
  showDoneNowOrders: boolean
  qrCode: string
  momoStatus: number
  loadingVoucher: boolean
  clickedSearch: boolean
  orderCode: string | null
  goFoodPin: string | null
  transferCode: string | null
  groupPaid: boolean
  code: string
  codes: string[]
}

export const ModalPayment = inject('rootStore')(
  observer(
    class extends React.Component<ModalPaymentProps, ModalPaymentState> {
      state = {
        receivedMoney: '',
        saving: false,
        showDoneNowOrders: false,
        qrCode: '',
        momoStatus: 0,
        couponCode: '',
        transferCode: '',
        groupPaid: false,
        orderCode: '',
        goFoodPin: '',
        code: '',
        codes: [],
        loadingVoucher: false,
        clickedSearch: false
      }

      handleInputMoney = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.orderStore.setReceivedMoney(parseInt(e.target.rawValue))
        this.setState({
          receivedMoney: e.target.rawValue
        })
      }

      handleSearchTextChange = (e: SyntheticEvent, { value }: InputProps) => {
        this.setState({ couponCode: value })
      }
      handleAirpayCodeChange = (e: SyntheticEvent, { value }: InputProps) => {
        this.setState({ code: value })
      }
      handleAddAirpayCode = (e: SyntheticEvent, { value }: InputProps) => {
        const codes = concat(this.state.codes, [this.state.code]) || []
        this.setState({ codes })
        this.props.orderStore.setCodes(codes)
      }
      handleInputOrderCode = (e: FormEvent<HTMLInputElement>) => {
        this.props.orderStore.setOrderCode(e.currentTarget.value)
        if (this.props.orderStore.orderPlatform === 'NOW') {
          this.props.orderStore.setNowOrderCode(e.currentTarget.value)
        }
        this.setState({
          orderCode: e.currentTarget.value
        })
      }
      // tách để dùng cho update socket check confirm transfer
      handleTransferCode = (value: string) => {
        this.props.orderStore.setTransferCode(value)
        this.setState({
          transferCode: value
        })
      }
      handleInputTransferCode = (e: FormEvent<HTMLInputElement>) => {
        this.handleTransferCode(e.currentTarget.value)
      }
      handleToggleGroupPaid = () => {
        this.props.orderStore.toggleGroupPaid()
        this.setState(prevState => ({
          groupPaid: !prevState.groupPaid
        }))
      }
      handleInputGoFoodPin = (e: FormEvent<HTMLInputElement>) => {
        this.props.orderStore.setGoFoodPin(e.currentTarget.value)
        this.setState({
          goFoodPin: e.currentTarget.value
        })
      }
      searchingVoucherPromise?: Bluebird<any>

      handleSearchCustomer = async () => {
        this.setState({
          loadingVoucher: true,
          clickedSearch: true
        })
        try {
          this.searchingVoucherPromise = voucherStore.searchVoucher(this.state.couponCode)
          const voucher = await this.searchingVoucherPromise
          if (voucher && voucher._id) {
            toast.success('Áp dụng mã giảm giá thành công', {
              autoClose: false
            })
            const orderStore = this.props.orderStore
            orderStore.setCouponCode(this.state.couponCode)
            orderStore.setCouponCodeValidated()
          }
        } catch (e) {
        } finally {
          if (!this.searchingVoucherPromise!.isCancelled()) {
            this.setState({ loadingVoucher: false })
          }
        }
      }

      debouncedSearchCustomer = debounce(this.handleSearchCustomer, 500)

      handleOpenModal = async ({ cashTotal }: { cashTotal: number }) => {
        this.props.orderStore.setOpenModalPayment(true)

        if (this.props.orderStore.orderPlatform === 'SAVOR_SHOP') {
          const outletCode = this.props.orderStore.getOutletCode()
          if (outletCode) {
            const toastId = toast.info('Đang lấy mã QR chuyển khoản', {
              autoClose: false
            })
            const shortUniqueId = Math.round(Math.random() * 1000000) // random 6 digits
            const transferContent = `SALE ${outletCode} ${shortUniqueId} POS` // ! must not exceed 25 characters to display on QR screen

            await this.props.orderStore.fetchLinkQrTransfer({
              transferContent,
              totalMoney: cashTotal,
              testAutoConfirm: true
            })

            if (this.props.orderStore.linkQrTransfer) {
              toast.update(toastId, {
                render: 'Lấy mã QR thành công!',
                autoClose: 3000,
                type: toast.TYPE.SUCCESS
              })
              // no need to await
              this.sendQRToScreen({
                totalMoney: cashTotal,
                transferContent
              })
            }
          }
        }

        if (this.props.orderStore.orderPlatform === 'NOW') {
          const toastId = toast.info('Đang lấy dữ liệu đơn NOW', {
            autoClose: false
          })

          await this.props.orderStore.fetchActiveNowOrders()

          toast.update(toastId, {
            render: 'Lấy dữ liệu đơn NOW thành công!',
            autoClose: 3000,
            type: toast.TYPE.SUCCESS
          })
        }

        const onlineOrderPaymentCode = get(this.props.orderStore, 'onlineOrder.payment.code')

        if (onlineOrderPaymentCode) {
          this.props.orderStore.setTransferCode(onlineOrderPaymentCode)
          this.setState({
            transferCode: onlineOrderPaymentCode
          })
        }
        const onlineOrderGroupPaid = get(this.props.orderStore, 'onlineOrder.groupPaid')
        if (onlineOrderGroupPaid !== this.props.orderStore.groupPaid) {
          this.props.orderStore.toggleGroupPaid()
          this.setState({ groupPaid: onlineOrderGroupPaid })
        }
      }

      handleCloseModal = () => {
        this.props.orderStore.setOpenModalPayment(false)
        if (this.props.orderStore.autosave) return
        this.props.orderStore.resetPayments()
        this.props.orderStore.setLinkQrTransfer('')
        this.props.orderStore.setLinkQrTransferError('')
        this.props.orderStore.setNowOrderCode('')
        this.props.orderStore.setOrderCode('')
        this.props.orderStore.setGoFoodPin('')
        this.setState({ receivedMoney: '' })
        this.setState({ orderCode: '' })
        this.setState({ transferCode: '' })
        this.setState({ groupPaid: false })
      }

      handleConfirm = () => {
        this.props.orderStore.sendOrder()
      }

      handleChooseServiceMode = (position: string) => {
        this.props.orderStore.setSelectedServiceMode(position)
      }

      handleRemovePayment = (uniqueId: string) => {
        this.props.orderStore.removePayment(uniqueId)
      }

      handleToggleShowDoneNowOrder = () => {
        this.setState(prevState => ({
          showDoneNowOrders: !prevState.showDoneNowOrders
        }))
      }
      handleConfirmTransfer = async (data: { transferContent: string; transferValue: number }) => {
        console.log('handleConfirmTransferFromSocket', data) // todo: remove after test
        const orderTransferContent = this.props.orderStore.transferContent
        const cashTotal = this.props.orderStore.cashTotal
        if (
          orderTransferContent &&
          !includes(data.transferContent, orderTransferContent) &&
          !includes(data.transferContent, replace(orderTransferContent, ' ', '')) // use include like erp abby
        ) {
          return
        }
        const receiveContent = `Đã nhận chuyển khoản số tiền ${data.transferValue} với nội dung ${data.transferContent}`
        if (data.transferValue !== cashTotal) {
          toast.error(
            `${receiveContent}. =====> CHƯA KHỚP với số tiền cần chuyển là ${formatVND(cashTotal)}`,
            {
              autoClose: false, // Không tự động đóng
              closeOnClick: true, // Đóng khi click
              closeButton: true // Hiện nút close
            }
          )
          return
        }
        toast.info(`${receiveContent}`)

        this.handleTransferCode(data.transferContent) // tự động điền mã giao dịch vào ô input
        // await this.props.orderStore.createAndDoneOrder() // tự động tạo và hoàn thành đơn
      }

      handleConfirmMomo = async (data: { momoTransId: string; orderId: string }) => {
        // console.log({ momoData: data, orderId: this.props.rootStore!.orderStore._id })
        if (this.props.rootStore!.orderStore._id === data.orderId) {
          this.props.orderStore.setMomoTransId(data.momoTransId)
          await this.props.orderStore.createAndDoneOrder()
        }
      }
      sendQRToScreen = async ({
        totalMoney,
        transferContent
      }: {
        totalMoney: number
        transferContent: string
      }): Promise<void> => {
        try {
          const result = await this.props.rootStore!.agent.post(
            '/api/serial/generate-qr',
            {
              amount: totalMoney,
              content: transferContent
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            },
            'http://localhost:21423'
          )
          console.log('Kết quả send QR to screen', result)
          toast.success('Đã gửi đến màn hình QR!')
        } catch (e) {
          console.log('Lỗi send QR to screen', e)
          toast.error('Có lỗi khi gửi đến màn hình QR!')
        }
      }
      componentDidMount() {
        this.props.rootStore!.realtimeEvent.subcribe('momoNotify', this.handleConfirmMomo)
        this.props.rootStore!.realtimeEvent.subcribe('transferNotify', this.handleConfirmTransfer)
      }

      componentWillUnmount() {
        this.props.rootStore!.realtimeEvent.unsubcribe('momoNotify', this.handleConfirmMomo)
        this.props.rootStore!.realtimeEvent.unsubcribe('transferNotify', this.handleConfirmTransfer)
      }

      getOrderCodeLabel() {}

      render() {
        const orderStore = this.props.orderStore
        const {
          openModalPayment,
          cashTotal,
          computedServiceMode,
          orderPlatform,
          shippingProvider,
          activeNowOrders,
          payments
        } = orderStore
        let nowOrders = activeNowOrders
        const isLoShip = orderPlatform === 'LOSHIP'
        const isVinId = orderPlatform === 'VINID'
        const isBAEMIN = orderPlatform === 'BAEMIN'
        const isLalamove = orderPlatform === 'LALAMOVE'
        const isGoFood = orderPlatform === 'GO_FOOD'
        const isNow = orderPlatform === 'NOW'

        let orderCodeLabel = ''
        if (!['SAVOR_SHOP'].includes(orderPlatform)) {
          // if (!['NOW', 'SAVOR_SHOP'].includes(orderPlatform)) {
          if (['BAEMIN', 'LOSHIP'].includes(orderPlatform)) {
            orderCodeLabel = 'Nhập mã đơn ' + orderPlatform
          } else if (['LALAMOVE'].includes(orderPlatform)) {
            orderCodeLabel = 'Nhập 4 số cuối SĐT tài xế ' + orderPlatform
          } else if (['GO_FOOD'].includes(orderPlatform)) {
            orderCodeLabel = 'Nhập 4 số cuối mã đơn ' + orderPlatform + '(Mã đơn bắt đầu bằng F-)'
          } else if (['NOW'].includes(orderPlatform)) {
            orderCodeLabel = 'Nhập 4 số cuối mã đơn NOW'
          } else {
            orderCodeLabel = 'Nhập 3 số cuối mã đơn ' + orderPlatform
          }
        }

        if (!this.state.showDoneNowOrders) {
          nowOrders = nowOrders.filter((nowOrder: any) => nowOrder.status !== 2)
        }

        if (payments && payments.length && payments[0].paymentType === 'MOMO') {
          const outletId = getObjectId(this.props.rootStore!.currentShiftStore.outlet)

          const amount = payments[0].amount
          const billId = orderStore._id
          const domain = getDomainMomo()
          const momoStoreId = this.props.rootStore!.outletListStore.getMomoStoreId(outletId)
          const storeSlug = getStoreSlugMomo(momoStoreId)
          const rawSignature = `storeSlug=${storeSlug}&amount=${amount}&billId=${billId}`
          const signature = getSignatureMomo(rawSignature)
          const url = `${domain}/pay/store/${storeSlug}?a=${amount}&b=${billId}&s=${signature}`
          // console.log({ url })

          orderStore.setLinkQrCode(url)
        }

        return (
          <Modal
            size="small"
            trigger={this.props.children}
            open={openModalPayment}
            onOpen={() => this.handleOpenModal({ cashTotal })}
            onClose={this.handleCloseModal}
          >
            <Modal.Header>
              <Header>Tính tiền</Header>
            </Modal.Header>
            <Modal.Content>
              <Grid>
                {orderPlatform === 'NOW' ? (
                  <>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Header>Liên kết hóa đơn NOW</Header>
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          onChange={this.handleToggleShowDoneNowOrder}
                          checked={this.state.showDoneNowOrders}
                          style={{ float: 'right' }}
                          label="Hiển thị đơn now đã hoàn thành"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Form>
                          <InputNowOrder
                            nowOrders={nowOrders}
                            onSelect={item => {
                              this.props.orderStore.setNowOrderCode(item ? item.code : '')
                            }}
                          />
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                    <Divider />
                  </>
                ) : null}
              </Grid>
              <Grid>
                {orderStore.activePaymentType === 'AIRPAY' ? (
                  <Fragment>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        {' '}
                        <Form>
                          <Form.Field>
                            <label>Nhập mã Airpay</label>
                            <Input
                              type="text"
                              value={this.state.code}
                              onChange={this.handleAirpayCodeChange}
                              action={{
                                color: 'teal',
                                labelPosition: 'right',
                                icon: 'search',
                                content: 'Thêm mã Airpay',
                                onClick: this.handleAddAirpayCode
                              }}
                              placeholder="Nhập mã Airpay..."
                            />
                          </Form.Field>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column verticalAlign="bottom">Danh sách mã Airpay</Grid.Column>
                      <Grid.Column textAlign="right" data-testid="airpay-code">
                        {' '}
                        {this.state.codes.map(code => {
                          return <p key={code}>{code}</p>
                        })}
                      </Grid.Column>
                    </Grid.Row>
                  </Fragment>
                ) : null}
              </Grid>
              <Grid>
                {this.props.orderStore.activePaymentType === 'CASH' && (
                  <Fragment>
                    <Grid.Row>
                      <Grid.Column>
                        <Segment raised color="green">
                          <Grid>
                            <Grid.Row columns={2}>
                              <Grid.Column>
                                <Header size="huge">Tiền mặt phải thu</Header>
                              </Grid.Column>
                              <Grid.Column textAlign="right">
                                <Header size="huge">
                                  <FormattedCurrency value={cashTotal} />
                                </Header>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Form>
                          <Form.Field>
                            <label>Tiền khách/Shipper đưa</label>
                            <InputCurrency
                              required
                              autoFocus
                              placeholder="Nhập tiền khách hàng/shipper đưa ..."
                              onChange={this.handleInputMoney}
                            />
                          </Form.Field>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column verticalAlign="bottom">Tiền trả lại</Grid.Column>
                      <Grid.Column textAlign="right" data-testid="payBackMoney">
                        <FormattedCurrency
                          value={
                            this.state.receivedMoney
                              ? parseInt(this.state.receivedMoney) - cashTotal
                              : 0
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Fragment>
                )}
                <Fragment>
                  <Grid style={{ marginTop: '1.5em' }}>
                    {payments &&
                    payments.length &&
                    payments[0].paymentType === 'TRANSFER' &&
                    (orderStore.linkQrTransfer || orderStore.linkQrTransferError) ? (
                      <Grid.Row>
                        <Grid.Column>
                          <Segment>
                            <Grid>
                              <Grid.Row columns={2}>
                                <Grid.Column width={8}>
                                  <img
                                    width={300}
                                    src={orderStore.linkQrTransfer}
                                    alt={orderStore.linkQrTransferError}
                                  />
                                </Grid.Column>
                                {!orderStore.linkQrTransferError ? (
                                  <Grid.Column width={8} verticalAlign="middle">
                                    <Header size="medium" color="black" textAlign="center">
                                      {`Quét mã QR để thanh toán và tự động tạo đơn. Tìm giao dịch ${this.props.orderStore.transferContent} ở channel #chuyen-khoan nhé`}
                                    </Header>
                                  </Grid.Column>
                                ) : null}
                              </Grid.Row>
                            </Grid>
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    ) : null}
                  </Grid>
                </Fragment>
                {this.props.orderStore.activePaymentType === 'TRANSFER' && (
                  <Fragment>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Form>
                          <Form.Field>
                            <label>Nhập kết quả giao dịch</label>
                            <Input
                              required
                              type="text"
                              autoFocus
                              placeholder="Nhập kết quả giao dịch"
                              value={this.state.transferCode}
                              onChange={this.handleInputTransferCode}
                            />
                          </Form.Field>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Checkbox
                          onChange={this.handleToggleGroupPaid}
                          checked={this.state.groupPaid}
                          style={{ float: 'left' }}
                          label="Thanh toán gộp"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Fragment>
                )}

                <Fragment>
                  {orderPlatform !== 'SAVOR_SHOP' && (
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Form>
                          <Form.Field>
                            <label>{orderCodeLabel}</label>

                            {isBAEMIN || isLalamove || isGoFood ? (
                              <Input
                                required
                                type="text"
                                maxLength={4}
                                autoFocus
                                placeholder="Nhập mã đơn"
                                value={this.state.orderCode}
                                onChange={this.handleInputOrderCode}
                              />
                            ) : !isNow ? (
                              <Input
                                required
                                type="text"
                                maxLength={!isLoShip && 3}
                                autoFocus
                                placeholder="Nhập mã đơn"
                                value={this.state.orderCode}
                                onChange={this.handleInputOrderCode}
                              />
                            ) : (
                              <Input
                                required
                                type="text"
                                maxLength={4}
                                autoFocus
                                placeholder="Nhập mã đơn"
                                value={this.state.orderCode}
                                onChange={this.handleInputOrderCode}
                              />
                            )}
                          </Form.Field>
                        </Form>
                        {isVinId ? (
                          <Form>
                            <Form.Field>
                              <label>Nhập mã Voucher VinId</label>
                              <Input
                                required
                                type="text"
                                autoFocus
                                placeholder="Nhập mã voucher"
                                value={this.state.couponCode}
                                onChange={this.handleSearchTextChange}
                              />
                            </Form.Field>
                          </Form>
                        ) : (
                          ''
                        )}
                        {isGoFood ? (
                          <Form>
                            <Form.Field>
                              <label>Nhập mã PIN đơn Go Food</label>
                              <Input
                                required
                                type="text"
                                autoFocus
                                placeholder="Nhập mã pin gồm 4 số đơn Go Food"
                                value={this.state.goFoodPin}
                                onChange={this.handleInputGoFoodPin}
                              />
                            </Form.Field>
                          </Form>
                        ) : (
                          ''
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Fragment>
              </Grid>

              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Header>Hình thức thanh toán</Header>
                    <AddPayment orderStore={orderStore} />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
              </Grid>
              <Fragment>
                <Grid style={{ marginTop: '1.5em' }}>
                  {payments &&
                  payments.length &&
                  payments[0].paymentType === 'MOMO' &&
                  orderStore.linkQrcode ? (
                    <Grid.Row>
                      <Grid.Column>
                        <Segment>
                          <Grid>
                            <Grid.Row columns={2}>
                              <Grid.Column width={6}>
                                <QRCode size={200} value={orderStore.linkQrcode} />
                              </Grid.Column>
                              <Grid.Column width={10} verticalAlign="middle">
                                {/* <Dimmer active inverted>
                                    <Loader size="huge" inverted content='Loading' />
                                  </Dimmer> */}
                                {!this.props.orderStore!.momoTransId && (
                                  <Header size="huge" color="black" textAlign="center">
                                    Quét mã QR để thanh toán và Done đơn.
                                  </Header>
                                )}
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  ) : null}
                </Grid>
              </Fragment>
            </Modal.Content>
            <Modal.Actions>
              {this.props.buttonActions.map(buttonAction => {
                return buttonAction
              })}
              <Button onClick={this.handleCloseModal} disabled={orderStore.saving}>
                Đóng
              </Button>
            </Modal.Actions>
          </Modal>
        )
      }
    }
  )
)
