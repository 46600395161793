import * as React from 'react'
import { Menu, Button } from 'semantic-ui-react'
import { RootStoreContext } from '../../../model/root-store'
import styles from './CurrentOrder.module.scss'
import { ModalPayment } from './ModalPayment'
import { handleAgentErrorResponse } from '../../../libs/error-handler'
import { toast } from 'react-toastify'
import { FormattedCurrency } from '../../../components/FormattedCurrency'
import {
  isBillTotalDiscountValid,
  drinkDiscountMap,
  getDrinkDiscountQuantity,
  checkValidGiftOpenCakeS6,
  giftS6Sku,
  checkAndGetValidGiftValentine,
  checkAndGetValidGift8T3
} from '../../../libs/bulk-discount'
import { observer } from 'mobx-react-lite'
import { isAfter, isBefore } from 'date-fns'
import { teacherDaySkus, xmasCakeSkus } from '../../../libs/skus.utils'
import { get, sumBy } from 'lodash'

export const CurrentOrderActions = observer(function CurrentOrderActions() {
  const rootStore = React.useContext(RootStoreContext)
  const orderStore = rootStore.orderStore
  const { canUseCurrentOutlet } = rootStore.authenticationStore
  const loggedInShift = rootStore.currentShiftStore.loggedInShift

  async function handleSendOrder() {
    try {
      await orderStore.sendOrder()
    } catch (e) {
      handleAgentErrorResponse(e)
      return
    }

    toast.success('Đã tạo Order')
  }

  async function handleCreateAndDoneOrder() {
    try {
      await orderStore.createAndDoneOrder()
    } catch (e) {
      handleAgentErrorResponse(e)
      return
    }
  }

  const buttons = loggedInShift
    ? [
        <Button
          key="done"
          positive
          color="green"
          onClick={handleCreateAndDoneOrder}
          disabled={orderStore.saving}
        >
          Done
        </Button>
      ]
    : canUseCurrentOutlet
    ? [
        <Button positive key="save" onClick={handleSendOrder} disabled={orderStore.saving}>
          Lưu Order
        </Button>
      ]
    : []

  // let alertMessages: string[] = ['- 1 x V8777 Sing-gum sampling doublemint']
  let alertMessages: string[] = []

  if (
    checkValidGiftOpenCakeS6(
      orderStore.items,
      orderStore.getOutletCode(),
      orderStore.orderPlatform,
      orderStore.orderType
    )
  ) {
    alertMessages.push(`- 1 x ${giftS6Sku} 	Lọ bánh quy rong biển trứng muôi 150g`)
  }

  // Valentine
  if (checkAndGetValidGiftValentine(orderStore.items, orderStore.onlineOrder, 'mica').isValid) {
    const giftData = checkAndGetValidGiftValentine(orderStore.items, orderStore.onlineOrder, 'mica')

    alertMessages.push(`- ${giftData.giftNum} x ${giftData.giftText}`)
  }
  if (checkAndGetValidGiftValentine(orderStore.items, orderStore.onlineOrder, 'card').isValid) {
    const giftData = checkAndGetValidGiftValentine(orderStore.items, orderStore.onlineOrder, 'card')

    alertMessages.push(`- ${giftData.giftNum} x ${giftData.giftText}`)
  }
  // 8/3
  if (checkAndGetValidGift8T3(orderStore.items, orderStore.onlineOrder, 'card').isValid) {
    const giftData = checkAndGetValidGift8T3(orderStore.items, orderStore.onlineOrder, 'card')

    alertMessages.push(`- ${giftData.giftNum} x ${giftData.giftText}`)
  }
  if (checkAndGetValidGift8T3(orderStore.items, orderStore.onlineOrder, 'sparkle').isValid) {
    const giftData = checkAndGetValidGift8T3(orderStore.items, orderStore.onlineOrder, 'sparkle')

    alertMessages.push(`- ${giftData.giftNum} x ${giftData.giftText}`)
  }

  const deliverDeadline =
    orderStore.onlineOrder && orderStore.onlineOrder.deadline
      ? new Date(orderStore.onlineOrder.deadline)
      : new Date()
  if (
    isAfter(deliverDeadline, new Date('2024-11-15T00:00:00+07:00')) &&
    isBefore(deliverDeadline, new Date('2024-11-21T00:00:00+07:00'))
  ) {
    const teacherDayQtyGift = sumBy(orderStore.items, item => {
      if (teacherDaySkus.includes(item.sku)) {
        return item.quantity
      }
      return 0
    })
    if (teacherDayQtyGift > 0) {
      alertMessages.push(`- ${teacherDayQtyGift} x V9262  Que cắm mica 20/11 Happy Teacher's Day`)
    }
    if (
      orderStore.saleTotal >= 300000 &&
      (!orderStore.onlineOrder ||
        get(orderStore, 'onlineOrder.shipping.provider') === 'Giao tại cửa hàng')
    ) {
      alertMessages.push(
        `- Chọn 1 trong 2 món: V2865 Que cắm giấy Happy Teacher's Day (chiếc) HOẶC V2863 Que cắm giấy thầy cô giáo (bộ)`
      )
    }
  }

  if (
    isAfter(deliverDeadline, new Date('2024-12-18T00:00:00+07:00')) &&
    isBefore(deliverDeadline, new Date('2024-12-26T00:00:00+07:00'))
  ) {
    const xmasQtyGift = sumBy(orderStore.items, item => {
      if (xmasCakeSkus.includes(item.sku)) {
        return item.quantity
      }
      return 0
    })
    if (xmasQtyGift > 0) {
      alertMessages.push(`- ${xmasQtyGift} x V9308 	Que cắm vòng nguyệt quế Merry Christmas`)
    }
    if (
      orderStore.saleTotal >= 300000 &&
      (!orderStore.onlineOrder ||
        get(orderStore, 'onlineOrder.shipping.provider') === 'Giao tại cửa hàng')
    ) {
      const outletCode = orderStore.getOutletCode()
      if (outletCode !== 'E9') {
        alertMessages.push(`- 1 x V2005 	Thiệp giáng sinh 9x9cm (chiếc)`)
      }
    }
  }

  if (isBillTotalDiscountValid(orderStore.items, orderStore.orderPlatform, orderStore.orderType)) {
    if (orderStore.orderPlatform === 'GRAB_FOOD') {
      alertMessages.push(`- 1 x Sữa Dutch Lady V2340`)
    }
    if (orderStore.orderPlatform === 'NOW') {
      alertMessages.push(`- 1 x Pepsi 245ml (ShopeeFood) V2570 `)
    }
  }

  let drinkAlertMessage = ''
  const drinkDiscountQuantity = getDrinkDiscountQuantity(orderStore.items)
  if (drinkDiscountQuantity) {
    // drinkAlertMessage += `Được giảm giá các món nước sau: `
  }

  return orderStore.computedItems.length > 0 ? (
    <Menu attached="bottom" className={styles['actions']}>
      {alertMessages.length > 0 ? (
        <div className={styles['actions__alert']}>
          <div className={styles['actions__alert-text']}>
            <strong>Đơn hàng được tặng:</strong>
            <br />
            {alertMessages.map((item, index) => {
              return (
                <p key={index} style={{ margin: 0 }}>
                  {item}
                </p>
              )
            })}
          </div>
        </div>
      ) : null}
      {drinkAlertMessage && orderStore.orderPlatform === 'SAVOR_SHOP' ? (
        <div className={styles['actions__alert']}>
          <p className={styles['actions__alert-text']}>
            {drinkAlertMessage}{' '}
            <span style={{ fontSize: 'medium' }}>
              ( Số lượng - <strong>{drinkDiscountQuantity}</strong>)
            </span>
            <br />
            {Object.values(drinkDiscountMap).map((item, index) => {
              return (
                <p key={index} className={styles['actions__alert-text']} style={{ margin: 0 }}>
                  - <strong>{item.description}</strong> sale còn{' '}
                  <strong>
                    <FormattedCurrency value={item.salePrice} />
                  </strong>
                  <br />
                </p>
              )
            })}
          </p>
        </div>
      ) : null}
      <Menu.Item
        as={() => (
          <ModalPayment buttonActions={buttons} orderStore={orderStore}>
            <Button color="green" fluid style={{ marginRight: 0 }}>
              Nhận <FormattedCurrency value={orderStore.cashTotal} />
            </Button>
          </ModalPayment>
        )}
      />
    </Menu>
  ) : null
})
